@import "bootstrap";
@import "assets/css/custom";
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

.lato {
  font-family: 'Lato', sans-serif;
}


.frame-picture {
  $width-border:5px;
  $width-square:80px;
  width: 85vw;
  height: 65vh;
  position: relative;
  .square:nth-child(1) {
    position: absolute;
    top: 0;
    left: 0;
    width: $width-square;
    height: $width-square;
    border-top: $width-border solid $--primary-ibrami;
    border-left: $width-border solid $--primary-ibrami;
  }

  .square:nth-child(2) {
    position: absolute;
    bottom: 0;
    left: 0;
    width: $width-square;
    height: $width-square;
    border-bottom: $width-border solid $--primary-ibrami;
    border-left: $width-border solid $--primary-ibrami;
  }

  .square:nth-child(3) {
    position: absolute;
    top: 0;
    right: 0;
    width: $width-square;
    height: $width-square;
    border-top: $width-border solid $--primary-ibrami;
    border-right: $width-border solid $--primary-ibrami;
  }

  .square:nth-child(4) {
    position: absolute;
    bottom: 0;
    right: 0;
    width: $width-square;
    height: $width-square;
    border-bottom: $width-border solid $--primary-ibrami;
    border-right: $width-border solid $--primary-ibrami;
  }



}


div.slick-slide.slick-active.slick-center.slick-current {
  div {
    display: initial !important;
  }
}

.slick-slide img {
  display: initial !important;
}

.terms-of-use {
  .section {
    div {
      margin-top: 1rem;
      text-align: justify;
    }
  }

}

.contact-us-button {
  button {
    background-color:  $--primary-ibrami;
    color: $white;
    border: none;
    transition: .2s all;
  }
  button:hover {
    transition: .3s all;
    background-color: #42A38D80;
  }
}

.request-now-button {
  button {
    background-color: transparent;
    border: 1px solid $white;
    color: white;
    transition: .2s all;
  }

  button:hover {
    transition: .3s all;
    background-color: $white !important;
    color: $black;
  }
}

.btn-reset {
  background-color: transparent;
  border: 0;
  box-shadow: none;
  user-select: none;
  outline: none;
}

.steps {
  min-width: 200px;
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  .label {
    min-width: 500px;
  }

  .option {
    min-width: 130px;
  }
}

.bg-opacity-90-hover:hover {
  transition: .2s all;
  opacity: 0.90;
}

.text-gray-600 {
  color: #6c757d;
}

.text-gray-700 {
  color: $gray-700;
}

.text-gray-800 {
  color: $gray-800;
}

.text-ibrami-primary {
  color: $--primary-ibrami;
}
.text-ibrami-secondary {
  color: $--secondary-ibrami;
}

.bg-ibrami-secondary {
  background-color: $--secondary-ibrami;
}

.bg-ibrami-primary {
  background-color: $--primary-ibrami;;
}

.btn-ibrami-primary {
  background-color: $--primary-ibrami;
  color: $white;
  border-radius: $border-radius-pill;
  padding: 0.5rem 1rem;
  transition: .3s ease-out;
}

.btn-ibrami-secondary {
  background-color: $--secondary-ibrami;
  color: $white;
  border-radius: $border-radius-pill;
  padding: 0.5rem 1rem;
  transition: .3s ease-out;
  border: 1px solid transparent;
}

.btn-ibrami-primary:hover {
  transition: .2s all;
  opacity: 0.90;
}

.btn-ibrami-secondary:hover {
  transition: .2s all;
  opacity: 0.90;
}

.hidden-scroll {
  overflow: hidden;
}

.btn-ibrami-outline-secondary {
  border: 1px solid $--secondary-ibrami;
  color: $--secondary-ibrami;
  border-radius: $border-radius-pill;
  padding: 0.5rem 1rem;
  transition: .3s ease-out;

  &:hover {
    background-color: $--secondary-ibrami;
    color: $white;
  }
}

.element-focus:hover {
  background-color: $gray-200;
  svg {
    color: $--secondary-ibrami;
  }
  span {
    color: $--secondary-ibrami;
  }
}

.file-denied {
  background-color: #fff5f8;
  color: #DC3545FF;
}

.trash-icon {
  span > div {
    background-color: $gray-400;
  }
  transition: .2s ease-out;
}

.trash-icon:hover {
  border-color: #DC3545FF;
  span > div {
    background-color: #DC3545FF;
    transition: .2s all;
  }

  svg {
    color: white;
    transition: .2s all;
  }

}

.custom-focus:focus {
  border-color: $--primary-ibrami;
  box-shadow: 0 0 0 0.25rem rgba(66, 163, 141, 0.25);
}

.custom-progress {

  .progress-bar {
    background-color: $--secondary-ibrami;
  }

}
