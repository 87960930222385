$--primary-ibrami: rgb(66, 163, 141);
$--secondary-ibrami: #6B5393;
$transition-link: color 0.2s ease !default;

.bg-image-8 {
  background: url(../images/old9.png) 50% /cover;
  height: 100% !important;
  width: 100% !important;
}

.bg-header-config-1 {
  height: 690px;
  width: 100%;
  object-fit: cover;
  object-position: center top;
}

.section-padding {
  padding: 90px 0;
}

.text-justify {
  text-align: justify;
}

.bg-color {
  background: $--primary-ibrami;
}

.bg-color-secondary {
  background: $--secondary-ibrami;
}

.bg-color-primary {
  background: $--primary-ibrami;
}
.color-primary {
  color: $--primary-ibrami !important;
}

.color-secondary {
  color: $--secondary-ibrami !important;
}
.radius-5 {
  border-radius: 5px;
}

.radius-10 {
  border-radius: 10px;
}

.small-border {
  width: 50px;
  height: 3px;
  border-left: none;
  border-right: none;
  display: block;
}

.card-three {
  background: #f2f6fe;
  border-radius: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  color: #fff;
  transition: 0.5s ease;
  align-items: center;
  text-align: justify;
  cursor: default;

  .bg-icon {
    display: inline-block;
    background: $--primary-ibrami;
    color: #fff;
    border-radius: 10px;
  }

  .title {
    color: #000;
    font-size: 32px;
  }

  .sub-title {
    color: #606060;
    font-size: 24px;
  }

  &:hover {
    background: $--primary-ibrami;
    color: #fff;
    transition: 0.5s ease;
    .bg-icon {
      background: #fff;
      color: $--secondary-ibrami;
    }
    .title {
      color: #fff;
    }
    .sub-title{
      color: #fff;
    }
  }

}

.card-themes {
  border: 1px solid $--primary-ibrami;
  border-radius: 10px;
  height: 100px;
  transition: 0.5s ease;
  cursor: default;
  i {
    color: $--secondary-ibrami;
  }

  span {

  }
  .color-text {

  }
  &:hover {
    background: $--primary-ibrami;
    transition: 0.5s ease;
    i {
      color: #fff;
    }
    span {
      color: #fff;
    }
  }
}



.social-media-icons {
  svg {
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    color: white;
    transition: 0.5s ease;
  }
  span {
    transition: 0.2s ease-out;
  }
}

.social-media-icons:hover {
  cursor: pointer;
  svg {
    color: #c7c6c6;
    transition: 0.2s ease;
  }
  span {
    color: #c7c6c6;
    transition: 0.2s ease;
  }
}

.back-top {
  position: absolute;
  right: 24px;
  top: -42px;
  color: white;
  border-radius: 50%;
  border: 1px solid white;
  cursor: pointer;
  transition: .2s ease-in-out;

  &:hover {
    color: white;
    background: black;
    transition: .2s ease-in-out;
    border-color: black;
  }
}

.curtain {
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 32px;
  font-size: 2.75rem;
  background-image: linear-gradient(90deg, #6B5393, transparent);

  .-weight {
    font-weight: 800;
  }

  .sub-title {
    font-size: 32px;
    font-weight: 400;
  }
}

.header-options {
  a {
    cursor: pointer;
    transition: .2s ease-out;
  }
  a:hover {
    transition: .2s ease;
    color: $--secondary-ibrami !important;
  }
}

.divider-header {
  div:nth-child(even) {
    height: 20px;
    width: 1px;
    border-right: 1px solid gainsboro;
  }
}

.text{
  font-family: 'Roboto', sans-serif;
  font-weight: 800;
  font-size: 40px;
}

.hide-border {
  border-right: 1px solid $white;
}

@media (min-width: 0px) and (max-width: 475px) {
  .bg-header-config-1 {
    height: 530px;
  }

  .obj-pos-0 {
    object-position: 1% !important;
  }
  .obj-pos-7 {
    object-position: 70% !important;
  }

  .curtain {
    font-size: 2.75rem;
    padding: 16px;
    left: unset;
    background-image: linear-gradient(90deg, rgba(109,83,147,1) 0%, rgba(0,212,255,0) 51%);

    .sub-title {
      font-size: 1.75rem !important;
    }
  }


}

@media (min-width: 476px) and (max-width: 768px) {
  .bg-header-config-1 {
    height: 550px;
  }
  .hide-border {
    border: initial;
  }
}
@media (min-width: 769px) and (max-width: 992px) {
  .bg-header-config-1 {
    height: 650px;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .bg-header-config-1 {
    height: 725px;
  }
}

@media (min-width: 576px) {
  .carousel-control-prev{
    width: initial;
    margin: -30px;
  }
  .carousel-control-next{
    width: initial;
    margin: -30px;
  }
}

.contour {
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #fff;
}




.topic-card.snip {
  background-image: linear-gradient(to bottom, #395d76, teal) !important;
  cursor: pointer;
}
.topic-card.snip h3 {
  position: absolute;
  top: 15px;
  color: white;
  left: 15px;
}

.topic-card.snip div {
  position: absolute;
  bottom: 15px;
  color: white;
  left: 15px;
}

.topic-card.snip img {
  width: 100%;
  transform: scale(1.1);
  vertical-align: top;
  opacity: .3;
  filter: grayscale(70%);
  height: 100%;
  object-fit: cover;
}
.topic-card.snip:hover img, .topic-card.snip.hover img {
  opacity: .05;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.topic-card.snip * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
}
.topic-card.snip {
  color: #fff;
  position: relative;
  overflow: hidden;
  width: 100%;
  border-radius: .28571429rem;
  background-color: #1a1a1a;
  color: #fff;
  text-align: left;
}

.letter1 {
  background-color: #f5f5f5;
  visibility: visible;
  content: '';
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  width: 100%;
  border: 1px solid rgba(34,36,38,.15);
  transform: rotate(-1.2deg);
  top: 0;
  z-index: -1;
}
.letter2 {
  transform: rotate(1.2deg);
  top: 0;
  z-index: -2;
  background-color: #fff;
  visibility: visible;
  content: '';
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  width: 100%;
  border: 1px solid rgba(34,36,38,.15);
}

.shareComponent {

  .img-thumbnail {
    border-radius: 33px !important;
    padding: 8px !important;
  }

  label {
    margin-top: 10px;
    color: #757575;
    font-size: 14px;
  }
  button.focus,
  button:focus {
    outline: 0;
    box-shadow: none !important
  }

  .ur.focus,
  .ur:focus {
    outline: 0;
    box-shadow: none !important
  }

  .message {
    font-size: 11px;
    color: #ee5535
  }
}
.bg-light {
  background-color: #afafaf !important;
}

//
// Dropzone plugin customization:  https://www.dropzonejs.com
//

// Basic
.dropzone {
  min-height: auto;
  padding: 1.5rem 1.75rem;
  text-align: center;
  cursor: pointer;
  border: 1px dashed var(--#{$prefix}primary);
  background-color: aliceblue;
  border-radius: $border-radius !important;

  .dz-message {
    margin: 0;
    display: flex;
    text-align: left;
  }

  .dz-preview {
    border-radius: $border-radius !important;
    margin: 0.75rem;

    .dz-image {
      border-radius: $border-radius !important;
      z-index: 1;
    }

    &.dz-file-preview {
      .dz-image {
        background: var(--#{$prefix}gray-200);
      }
    }
  }

  .dz-success-mark,
  .dz-error-mark {
    $size: 40px;

    margin-left: -(divide($size, 2)) !important;
    margin-top: -(divide($size, 2)) !important;

    svg {
      height: $size !important;
      width: $size !important;
    }
  }

  .dz-remove {
    $size: 1.65rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: $size;
    width: $size;
    font-size: 1rem;
    text-indent: -9999px;
    white-space: nowrap;
    position: absolute;
    z-index: 2;
    background-color: var(--#{$prefix}body-bg) !important;
    box-shadow: var(--#{$prefix}box-shadow);
    border-radius: 100%;
    top: -(divide($size, 2));
    right: -(divide($size, 2));

    &:after {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      display: block;
      content: '';
      mask-size: 40%;
      -webkit-mask-size: 40%;
    }


  }

  .dz-error-message {
    color: var(--#{$prefix}danger-inverse);
    background: var(--#{$prefix}danger);
  }
}

// Queue upload
.dropzone.dropzone-queue {
  border: 0;
  padding: 0;
  background-color: transparent;
  text-align: left;

  .dz-message {
    display: none;
  }

  .dropzone-panel {
    .dropzone-upload,
    .dropzone-remove-all {
      display: none;
    }
  }

  .dropzone-item {
    display: flex;
    align-items: center;
    margin-top: 0.75rem;
    @include border-radius($border-radius);
    padding: 0.5rem 1rem;
    background-color: var(--#{$prefix}gray-100);

    .dropzone-file {
      flex-grow: 1;

      .dropzone-filename {
        font-size: 0.9rem;
        font-weight: 500;
        color: var(--#{$prefix}gray-600);
        text-overflow: ellipsis;
        margin-right: 0.5rem;

        &:hover {
          color: var(--#{$prefix}primary);
        }
      }

      .dropzone-error {
        margin-top: 0.25rem;
        font-size: 0.9rem;
        font-weight: 400;
        color: var(--#{$prefix}danger);
        text-overflow: ellipsis;
      }
    }

    .dropzone-progress {
      width: 15%;

      .progress {
        height: 5px;
        @include transition;
      }
    }

    .dropzone-toolbar {
      margin-left: 1rem;
      display: flex;
      flex-wrap: nowrap;

      .dropzone-start,
      .dropzone-cancel,
      .dropzone-delete {
        height: 25px;
        width: 25px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: $transition-link;

        > i {
          transition: $transition-link;
          font-size: 0.8rem;
          color: var(--#{$prefix}gray-600);
        }

        &:hover {
          transition: $transition-link;
          > i {
            color: var(--#{$prefix}primary);
          }
        }
      }

      .dropzone-start {
        transition: $transition-link;
      }
    }
  }
}
